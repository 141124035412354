import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import {
  BUTTON,
  FOUR_BUTTONS,
  ONE_BUTTON_ONE_ROCKER,
  ONE_SLIDER_ONE_BUTTON,
  ONE_SLIDER_ONE_ROCKER,
  ONE_SLIDER_TWO_BUTTONS,
  ROCKER,
  SLIDER,
  TWO_BUTTONS,
  TWO_BUTTONS_ONE_ROCKER,
  TWO_ROCKERS,
  TWO_SLIDERS,
} from "../../../../constants/InsertType";
import {
  BOTTOM_LINE,
  ELEMENTS,
  ICON,
  LAYOUTS,
  POSITIONS,
  SEPARATION_HALF_LINE,
  SEPARATION_LINE,
  SEPARATION_LINE_HORIZONTAL,
  SEPARATION_LINE_VERTICAL,
  TEXT,
  TOP_LINE,
  WITH_TEXT,
  WITHOUT_TEXT,
} from "../../../../constants/ItemsDimensions";
import { IDesignDimensions } from "../../../../interfaces/IDesignDimensions";

type Dimensions = {
  actualWidthMm: number;
  actualHeightMm: number;
  actualPosXMm?: number;
  actualPosYMm?: number;
  actualStartXMm?: number;
  actualStartYMm?: number;
  actualEndXMm?: number;
  actualEndYMm?: number;
};

const getSafeValue = (designSizes: IDesignDimensions, path: string[], defaultValue: number = 0) => {
  return path.reduce((acc: any, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue), designSizes);
};

const getDimensions = (designSizes: IDesignDimensions, keys: string[]) => ({
  actualWidthMm: getSafeValue(designSizes, [...keys, "width"]),
  actualHeightMm: getSafeValue(designSizes, [...keys, "height"]),
  actualPosXMm: getSafeValue(designSizes, [...keys, "posX"]),
  actualPosYMm: getSafeValue(designSizes, [...keys, "posY"]),
});

const getButtonDimensions = (
  designSizes: IDesignDimensions,
  type: EInsertDetailItemType,
  keyLayoutValue: string | string[]
) => {
  const iconOrText = type === EInsertDetailItemType.Icon ? ICON : TEXT;
  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);
  const fullPath = [...resolveKeyPath(keyLayoutValue), iconOrText];
  return getDimensions(designSizes, fullPath);
};

const getSeparationLineDimensions = (designSizes: IDesignDimensions, keyLayoutValue: string | string[]) => {
  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);

  const fullPath = [...resolveKeyPath(keyLayoutValue)];

  const lineData = getSafeValue(designSizes, fullPath);

  return lineData
    ? {
        actualStartXMm: lineData.startX || 0,
        actualStartYMm: lineData.startY || 0,
        actualEndXMm: lineData.endX || 0,
        actualEndYMm: lineData.endY || 0,
        actualWidthMm: lineData.width || 0,
        actualHeightMm: lineData.height || 0,
      }
    : initializeDimensions();
};

const getSliderDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string,
  indexArray: string[],
  keyLayoutValue: string | string[]
): Dimensions => {
  const withText =
    type === EInsertDetailItemType.Text ||
    insertDetail.items.some((item, _, items) =>
      items.some(
        (otherItem) =>
          otherItem.indexDetail === item.indexDetail &&
          ((item.type === EInsertDetailItemType.Text && otherItem.type === EInsertDetailItemType.Icon) ||
            (item.type === EInsertDetailItemType.Icon && otherItem.type === EInsertDetailItemType.Text))
      )
    );
  const key = withText ? WITH_TEXT : WITHOUT_TEXT;
  const iconOrText = type === EInsertDetailItemType.Icon ? ICON : TEXT;

  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);

  if (indexDetail === indexArray[0]) {
    const fullPath = [...resolveKeyPath(keyLayoutValue), key, iconOrText];
    return {
      ...getDimensions(designSizes, fullPath),
      actualStartXMm: 0,
      actualStartYMm: 0,
      actualEndXMm: 0,
      actualEndYMm: 0,
    };
  }

  const lineData =
    indexDetail === indexArray[1]
      ? getSafeValue(designSizes, [...resolveKeyPath(keyLayoutValue), key, TOP_LINE])
      : indexDetail === indexArray[2]
      ? getSafeValue(designSizes, [...resolveKeyPath(keyLayoutValue), key, BOTTOM_LINE])
      : null;

  return lineData
    ? {
        actualStartXMm: lineData.startX || 0,
        actualStartYMm: lineData.startY || 0,
        actualEndXMm: lineData.endX || 0,
        actualEndYMm: lineData.endY || 0,
        actualWidthMm: lineData.width || 0,
        actualHeightMm: lineData.height || 0,
      }
    : initializeDimensions();
};

const getRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string,
  indexArray: string[],
  keyLayoutValue: string | string[]
): Dimensions => {
  const verticalPositionMap: Record<string, string> = {
    [indexArray[0]]: insertDetail.rotate === 180 ? POSITIONS.BOTTOM : POSITIONS.TOP,
    [indexArray[1]]: POSITIONS.MIDDLE,
    [indexArray[2]]: insertDetail.rotate === 180 ? POSITIONS.TOP : POSITIONS.BOTTOM,
  };

  const horizontalPositionMap: Record<string, string> = {
    [indexArray[0]]: POSITIONS.RIGHT,
    [indexArray[1]]: POSITIONS.MIDDLE,
    [indexArray[2]]: POSITIONS.LEFT,
  };

  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);

  let position =
    insertDetail.rotate === 0 || insertDetail.rotate === 180
      ? verticalPositionMap[indexDetail]
      : horizontalPositionMap[indexDetail];

  if (
    insertDetail?.insertType?.name === ONE_BUTTON_ONE_ROCKER ||
    insertDetail?.insertType?.name === TWO_BUTTONS_ONE_ROCKER
  ) {
    position =
      insertDetail.rotate === 90 || insertDetail.rotate === 270
        ? verticalPositionMap[indexDetail]
        : horizontalPositionMap[indexDetail];
  }

  if (position) {
    const iconOrText = type === EInsertDetailItemType.Icon ? ICON : TEXT;
    const fullPath = [...resolveKeyPath(keyLayoutValue), `${position}_${iconOrText}`];
    return {
      ...getDimensions(designSizes, fullPath),
      actualStartXMm: 0,
      actualStartYMm: 0,
      actualEndXMm: 0,
      actualEndYMm: 0,
    };
  }

  return initializeDimensions();
};

const getSliderRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
) => {
  const isFlipped = insertDetail.isFlipped;

  const separationLineDimensions = getSeparationLineDimensions(designSizes, [
    isFlipped ? LAYOUTS.ROCKER_SLIDER : LAYOUTS.SLIDER_ROCKER,
    SEPARATION_LINE,
  ]);

  const sliderDimensions = getSliderDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.ONE, ELEMENTS.SIX, ELEMENTS.SEVEN],
    [isFlipped ? LAYOUTS.ROCKER_SLIDER : LAYOUTS.SLIDER_ROCKER, LAYOUTS.SLIDER]
  );

  const rockerDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.TWO, ELEMENTS.THREE, ELEMENTS.FOUR],
    [isFlipped ? LAYOUTS.ROCKER_SLIDER : LAYOUTS.SLIDER_ROCKER, LAYOUTS.ROCKER]
  );

  const hasNonZeroSeparationLine = Object.values(separationLineDimensions).some((value) => value !== 0);
  const hasNonZeroSlider = Object.values(sliderDimensions).some((value) => value !== 0);
  const hasNonZeroRocker = Object.values(rockerDimensions).some((value) => value !== 0);

  if (hasNonZeroRocker) {
    return rockerDimensions;
  } else if (hasNonZeroSlider) {
    return sliderDimensions;
  } else if (hasNonZeroSeparationLine) {
    return separationLineDimensions;
  } else return initializeDimensions();
};

const getSliderSliderDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
) => {
  const separationLineDimenstions = getSeparationLineDimensions(designSizes, [LAYOUTS.SLIDER_SLIDER, SEPARATION_LINE]);

  const sliderLeftDimensions = getSliderDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.ONE, ELEMENTS.THREE, ELEMENTS.FOUR],
    [LAYOUTS.SLIDER_SLIDER, POSITIONS.SLIDER_LEFT]
  );

  const sliderRightDimensions = getSliderDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.TWO, ELEMENTS.FIVE, ELEMENTS.SIX],
    [LAYOUTS.SLIDER_SLIDER, POSITIONS.SLIDER_RIGHT]
  );

  const hasNonZeroSeparationLine = Object.values(separationLineDimenstions).some((value) => value !== 0);
  const hasNonZeroSliderLeft = Object.values(sliderLeftDimensions).some((value) => value !== 0);
  const hasNonZeroSliderRight = Object.values(sliderRightDimensions).some((value) => value !== 0);

  if (hasNonZeroSliderLeft) {
    return sliderLeftDimensions;
  } else if (hasNonZeroSliderRight) {
    return sliderRightDimensions;
  } else if (hasNonZeroSeparationLine) {
    return separationLineDimenstions;
  } else return initializeDimensions();
};

const getSliderButtonDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
) => {
  const isFlipped = insertDetail.isFlipped;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [
      isFlipped ? LAYOUTS.BUTTON_SLIDER : LAYOUTS.SLIDER_BUTTON,
      SEPARATION_LINE,
    ]);
  } else if (
    type === EInsertDetailItemType.Slider ||
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.ONE) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.ONE)
  ) {
    return getSliderDimensions(
      designSizes,
      insertDetail,
      type,
      indexDetail,
      [ELEMENTS.ONE, ELEMENTS.THREE, ELEMENTS.FOUR],
      [isFlipped ? LAYOUTS.BUTTON_SLIDER : LAYOUTS.SLIDER_BUTTON, LAYOUTS.SLIDER]
    );
  } else if (
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.TWO) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.TWO)
  ) {
    return getButtonDimensions(designSizes, type, [
      isFlipped ? LAYOUTS.BUTTON_SLIDER : LAYOUTS.SLIDER_BUTTON,
      LAYOUTS.BUTTON,
    ]);
  }

  return initializeDimensions();
};

const getButtonButtonDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const isVertical = insertDetail.rotate === 0 || insertDetail.rotate === 180;
  const position = isVertical ? POSITIONS.VERTICAL : POSITIONS.HORIZONTAL;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [LAYOUTS.BUTTON_BUTTON, position, SEPARATION_LINE]);
  }

  const buttonPosition = isVertical
    ? indexDetail === ELEMENTS.ONE
      ? POSITIONS.BUTTON_LEFT
      : POSITIONS.BUTTON_RIGHT
    : indexDetail === ELEMENTS.ONE
    ? POSITIONS.BUTTON_TOP
    : POSITIONS.BUTTON_BOTTOM;

  const buttonDimensions = getButtonDimensions(designSizes, type, [LAYOUTS.BUTTON_BUTTON, position, buttonPosition]);

  return buttonDimensions || initializeDimensions();
};

const getRockerRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const isVertical = insertDetail.rotate === 0 || insertDetail.rotate === 180;
  const position = isVertical ? POSITIONS.VERTICAL : POSITIONS.HORIZONTAL;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [LAYOUTS.ROCKER_ROCKER, position, SEPARATION_LINE]);
  }

  const rockerLeftDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.ONE, ELEMENTS.THREE, ELEMENTS.FIVE],
    [LAYOUTS.ROCKER_ROCKER, position, isVertical ? POSITIONS.ROCKER_LEFT : POSITIONS.ROCKER_TOP]
  );

  const rockerRightDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.TWO, ELEMENTS.FOUR, ELEMENTS.SIX],
    [LAYOUTS.ROCKER_ROCKER, position, isVertical ? POSITIONS.ROCKER_RIGHT : POSITIONS.ROCKER_BOTTOM]
  );

  const hasNonZeroRockerLeft = Object.values(rockerLeftDimensions).some((value) => value !== 0);
  const hasNonZeroRockerRight = Object.values(rockerRightDimensions).some((value) => value !== 0);

  return hasNonZeroRockerLeft
    ? rockerLeftDimensions
    : hasNonZeroRockerRight
    ? rockerRightDimensions
    : initializeDimensions();
};

const getButtonRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const topRockerBottomButton = insertDetail.rotate === 0;
  const leftButtonRightRocker = insertDetail.rotate === 90;
  const topButtonBottomRocker = insertDetail.rotate === 180;
  const rightButtonLeftRocker = insertDetail.rotate === 270;

  if (type === EInsertDetailItemType.SeparationLine) {
    let path: string[];
    if (topRockerBottomButton) {
      path = [LAYOUTS.ROCKER_BUTTON, POSITIONS.HORIZONTAL, SEPARATION_LINE];
    } else if (leftButtonRightRocker) {
      path = [LAYOUTS.BUTTON_ROCKER, POSITIONS.VERTICAL, SEPARATION_LINE];
    } else if (topButtonBottomRocker) {
      path = [LAYOUTS.BUTTON_ROCKER, POSITIONS.HORIZONTAL, SEPARATION_LINE];
    } else {
      path = [LAYOUTS.ROCKER_BUTTON, POSITIONS.VERTICAL, SEPARATION_LINE];
    }

    return getSeparationLineDimensions(designSizes, path);
  }

  const buttonDimensions =
    indexDetail === ELEMENTS.FOUR &&
    getButtonDimensions(
      designSizes,
      type,
      topRockerBottomButton
        ? [LAYOUTS.ROCKER_BUTTON, POSITIONS.HORIZONTAL, POSITIONS.BUTTON_BOTTOM]
        : leftButtonRightRocker
        ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.VERTICAL, POSITIONS.BUTTON_LEFT]
        : topButtonBottomRocker
        ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.HORIZONTAL, POSITIONS.BUTTON_TOP]
        : [LAYOUTS.ROCKER_BUTTON, POSITIONS.VERTICAL, POSITIONS.BUTTON_RIGHT]
    );

  const rockerDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    topRockerBottomButton || rightButtonLeftRocker
      ? [ELEMENTS.THREE, ELEMENTS.TWO, ELEMENTS.ONE]
      : [ELEMENTS.ONE, ELEMENTS.TWO, ELEMENTS.THREE],
    topRockerBottomButton
      ? [LAYOUTS.ROCKER_BUTTON, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_TOP]
      : leftButtonRightRocker
      ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.VERTICAL, POSITIONS.ROCKER_RIGHT]
      : topButtonBottomRocker
      ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_BOTTOM]
      : [LAYOUTS.ROCKER_BUTTON, POSITIONS.VERTICAL, POSITIONS.ROCKER_LEFT]
  );

  const hasNonZeroButton = Object.values(buttonDimensions).some((value) => value !== 0);
  const hasNonZeroRocker = Object.values(rockerDimensions).some((value) => value !== 0);

  if (hasNonZeroButton) {
    return buttonDimensions || initializeDimensions();
  } else if (hasNonZeroRocker) {
    return rockerDimensions;
  }

  return initializeDimensions();
};

const getSliderButtonsDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const isFlipped = insertDetail.isFlipped;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [
      isFlipped ? LAYOUTS.BUTTONS_SLIDER : LAYOUTS.SLIDER_BUTTONS,
      indexDetail === ELEMENTS.SIX ? SEPARATION_LINE : SEPARATION_HALF_LINE,
    ]);
  } else if (
    type === EInsertDetailItemType.Slider ||
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.ONE) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.ONE)
  ) {
    return getSliderDimensions(
      designSizes,
      insertDetail,
      type,
      indexDetail,
      [ELEMENTS.ONE, ELEMENTS.FOUR, ELEMENTS.FIVE],
      [isFlipped ? LAYOUTS.BUTTONS_SLIDER : LAYOUTS.SLIDER_BUTTONS, LAYOUTS.SLIDER]
    );
  } else if (
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.TWO) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.TWO) ||
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.THREE) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.THREE)
  ) {
    return getButtonDimensions(designSizes, type, [
      isFlipped ? LAYOUTS.BUTTONS_SLIDER : LAYOUTS.SLIDER_BUTTONS,
      LAYOUTS.BUTTONS,
      indexDetail === ELEMENTS.TWO ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM,
    ]);
  }

  return initializeDimensions();
};

const getButtonsRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const topButtonsBottomRocker = insertDetail.rotate === 0;
  const leftRockerRightButtons = insertDetail.rotate === 90;
  const topRockerBottomButtons = insertDetail.rotate === 180;
  const rightRockerLeftButtons = insertDetail.rotate === 270;

  if (type === EInsertDetailItemType.SeparationLine) {
    let path;
    if (topButtonsBottomRocker) {
      path = [
        LAYOUTS.BUTTONS_ROCKER,
        POSITIONS.HORIZONTAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    } else if (leftRockerRightButtons) {
      path = [
        LAYOUTS.ROCKER_BUTTONS,
        POSITIONS.VERTICAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    } else if (topRockerBottomButtons) {
      path = [
        LAYOUTS.ROCKER_BUTTONS,
        POSITIONS.HORIZONTAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    } else {
      path = [
        LAYOUTS.BUTTONS_ROCKER,
        POSITIONS.VERTICAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    }
    return getSeparationLineDimensions(designSizes, path);
  }

  const buttonDimensions = getButtonDimensions(
    designSizes,
    type,
    topButtonsBottomRocker
      ? [
          LAYOUTS.BUTTONS_ROCKER,
          POSITIONS.HORIZONTAL,
          POSITIONS.BUTTONS_TOP,
          indexDetail === ELEMENTS.ONE ? POSITIONS.BUTTON_LEFT : POSITIONS.BUTTON_RIGHT,
        ]
      : leftRockerRightButtons
      ? [
          LAYOUTS.ROCKER_BUTTONS,
          POSITIONS.VERTICAL,
          POSITIONS.BUTTONS_RIGHT,
          indexDetail === ELEMENTS.ONE ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM,
        ]
      : topRockerBottomButtons
      ? [
          LAYOUTS.ROCKER_BUTTONS,
          POSITIONS.HORIZONTAL,
          POSITIONS.BUTTONS_BOTTOM,
          indexDetail === ELEMENTS.TWO ? POSITIONS.BUTTON_LEFT : POSITIONS.BUTTON_RIGHT,
        ]
      : [
          LAYOUTS.BUTTONS_ROCKER,
          POSITIONS.VERTICAL,
          POSITIONS.BUTTONS_LEFT,
          indexDetail === ELEMENTS.TWO ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM,
        ]
  );

  const rockerDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    topButtonsBottomRocker || rightRockerLeftButtons
      ? [ELEMENTS.FIVE, ELEMENTS.FOUR, ELEMENTS.THREE]
      : [ELEMENTS.THREE, ELEMENTS.FOUR, ELEMENTS.FIVE],
    topButtonsBottomRocker
      ? [LAYOUTS.BUTTONS_ROCKER, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_BOTTOM]
      : leftRockerRightButtons
      ? [LAYOUTS.ROCKER_BUTTONS, POSITIONS.VERTICAL, POSITIONS.ROCKER_LEFT]
      : topRockerBottomButtons
      ? [LAYOUTS.ROCKER_BUTTONS, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_TOP]
      : [LAYOUTS.BUTTONS_ROCKER, POSITIONS.VERTICAL, POSITIONS.ROCKER_RIGHT]
  );

  const hasNonZeroButton = Object.values(buttonDimensions).some((value) => value !== 0);
  const hasNonZeroRocker = Object.values(rockerDimensions).some((value) => value !== 0);

  if (hasNonZeroButton) {
    return buttonDimensions || initializeDimensions();
  } else if (hasNonZeroRocker) {
    return rockerDimensions;
  }

  return initializeDimensions();
};

const getFourButtonsDimensions = (
  designSizes: IDesignDimensions,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [
      LAYOUTS.FOUR_BUTTONS,
      indexDetail === ELEMENTS.FIVE ? SEPARATION_LINE_VERTICAL : SEPARATION_LINE_HORIZONTAL,
    ]);
  }

  const buttonPositions = {
    [ELEMENTS.ONE]: POSITIONS.TOP_LEFT,
    [ELEMENTS.TWO]: POSITIONS.TOP_RIGHT,
    [ELEMENTS.THREE]: POSITIONS.BOTTOM_LEFT,
    [ELEMENTS.FOUR]: POSITIONS.BOTTOM_RIGHT,
  };

  const buttonDimensions = getButtonDimensions(designSizes, type, [LAYOUTS.FOUR_BUTTONS, buttonPositions[indexDetail]]);

  return buttonDimensions || initializeDimensions();
};

const initializeDimensions = () => ({
  actualWidthMm: 0,
  actualHeightMm: 0,
  actualPosXMm: 0,
  actualPosYMm: 0,
  actualStartXMm: 0,
  actualStartYMm: 0,
  actualEndXMm: 0,
  actualEndYMm: 0,
});

export const getItemsDimensions = (
  indexDetail: string,
  layoutName: string | undefined,
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType
) => {
  if (!designSizes) {
    console.warn("designSizes data is undefined");
    return;
  }

  let dimensions: Dimensions = initializeDimensions();

  switch (layoutName) {
    case BUTTON:
      dimensions = getButtonDimensions(designSizes, type, LAYOUTS.BUTTON);
      break;
    case SLIDER:
      dimensions = getSliderDimensions(
        designSizes,
        insertDetail,
        type,
        indexDetail,
        [ELEMENTS.ONE, ELEMENTS.TWO, ELEMENTS.THREE],
        LAYOUTS.SLIDER
      );
      break;
    case ROCKER:
      const isVertical = insertDetail.rotate === 0 || insertDetail.rotate === 180;
      dimensions = getRockerDimensions(
        designSizes,
        insertDetail,
        type,
        indexDetail,
        [ELEMENTS.ONE, ELEMENTS.TWO, ELEMENTS.THREE],
        isVertical ? POSITIONS.VERTICAL_ROCKER : POSITIONS.HORIZONTAL_ROCKER
      );
      break;
    case ONE_SLIDER_ONE_ROCKER:
      dimensions = getSliderRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_SLIDERS:
      dimensions = getSliderSliderDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case ONE_SLIDER_ONE_BUTTON:
      dimensions = getSliderButtonDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_BUTTONS:
      dimensions = getButtonButtonDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_ROCKERS:
      dimensions = getRockerRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case ONE_BUTTON_ONE_ROCKER:
      dimensions = getButtonRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case ONE_SLIDER_TWO_BUTTONS:
      dimensions = getSliderButtonsDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_BUTTONS_ONE_ROCKER:
      dimensions = getButtonsRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case FOUR_BUTTONS:
      dimensions = getFourButtonsDimensions(designSizes, type, indexDetail);
      break;
    default:
      console.warn("Unrecognized layout:", layoutName);
      break;
  }

  return dimensions;
};
