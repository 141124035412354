import { Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IProductFavoriteDesignResponse } from "../../../interfaces/Project/Response/ProductDesignResponse";
import "../RecentProjects/RecentProjects.css";
import SeeAllButton from "../RecentProjects/SeeAllButton";
import { NUMBER_OF_SINGLE_COVER } from "../../../constants/NumberValues";
import ThreeDotsMenuFavDesigns from "../../../components/ThreeDotsMenuFavDesigns/ThreeDotsMenuFavDesigns";
import FavoriteDesignsPreview, { favoriteDesignsPreviewRef } from "../../FavoriteDesigns/FavoriteDesignsPreview";
import { BASIC_GLOBAL_DATE_FORMAT } from "../../../constants/DateFormatTypes";
import { FAVORITE_DESIGNS_PATH } from "../../../constants/Paths";

const RecentFavoriteDesigns: FC<{}> = (props) => {
  const { t } = useTranslation();

  const { favoriteDesigns } = useStoreState((state) => state.designs.data);
  const { favoriteDesignData } = useStoreState((state) => state.designs.data);

  const [favoriteDesignsList, setFavoriteDesignsList] = useState<IProductFavoriteDesignResponse[]>([]);
  const {
    thunkGetFavoriteDesign,
    thunkGetFavoriteDesignById,
    setProductDesignPreview,
    setUserDesignDataFromProductDesignResponse,
  } = useStoreActions((actions) => actions.designs);

  const fetchFavoriteDesigns = async () => {
    try {
      const favDesigns = await thunkGetFavoriteDesign();
      setFavoriteDesignsList(favDesigns);
    } catch (error) {
      console.error("Failed to fetch favorite designs:", error);
    }
  };

  useEffect(() => {
    fetchFavoriteDesigns();
  }, []);

  useEffect(() => {
    if (favoriteDesigns.length !== favoriteDesignsList.length) {
      fetchFavoriteDesigns();
    }
  }, [favoriteDesigns]);

  const handleOpenDetails = async (favoriteDesignId: string, numberOfCovers: number | undefined) => {
    let designToSet;

    if (favoriteDesignData?.favoriteDesignId === favoriteDesignId) {
      designToSet = favoriteDesignData;
    } else {
      try {
        designToSet = await thunkGetFavoriteDesignById(favoriteDesignId);
      } catch (error) {
        console.error("Failed to fetch design:", error);
        return;
      }
    }

    if (designToSet) {
      designToSet.numberOfCovers = numberOfCovers;
      setUserDesignDataFromProductDesignResponse(designToSet);
      setProductDesignPreview(designToSet);
      favoriteDesignsPreviewRef.current?.open();
    }
  };

  return (
    <div className="recentContainer">
      <div className="recentProjects">
        <div className="recentProjects__headerContainer">
          <Typography
            variant="h3"
            fontSize="18px"
            fontWeight={500}
            p="30px 30px 16px 30px"
            m={0}
          >
            {t("recentFavoriteDesigns")}
          </Typography>
          <SeeAllButton pathname={FAVORITE_DESIGNS_PATH} />
        </div>
        <div className="recentProjects__table">
          {(favoriteDesignsList != null && favoriteDesignsList.length && (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t("favoriteDesigns.table.header.preview")}</TableCell>
                    <TableCell align="left">{t("favoriteDesigns.table.header.description")}</TableCell>
                    <TableCell align="right">{t("favoriteDesigns.table.header.create_date")}</TableCell>
                    <TableCell align="right">{t("table.head_cell.label_action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {favoriteDesignsList
                    .sort((favDesign1, favDesign2) => (favDesign1.createdAt < favDesign2.createdAt ? 1 : -1))
                    .slice(0, 2)
                    .map((favoriteDesign) => (
                      <TableRow key={favoriteDesign.favoriteDesignId}>
                        <TableCell
                          align="left"
                          className="projects__table_row_cell"
                        >
                          <div className="center-content">
                            <div
                              className="thumb"
                              onClick={() => handleOpenDetails(favoriteDesign.favoriteDesignId, NUMBER_OF_SINGLE_COVER)}
                            >
                              {favoriteDesign?.coverImage && (
                                <img
                                  src={favoriteDesign.coverImage}
                                  alt={`${favoriteDesign.name}, ${t(`insertcolors.${favoriteDesign.colorCode}`)}`}
                                />
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="projects__table_row_cell"
                        >{`${favoriteDesign.name}, ${t(`insertcolors.${favoriteDesign.colorCode}`)}`}</TableCell>
                        <TableCell
                          align="right"
                          className="projects__table_row_cell"
                        >
                          {moment(favoriteDesign.createdAt).format(BASIC_GLOBAL_DATE_FORMAT)}
                        </TableCell>
                        <TableCell align="right">
                          <ThreeDotsMenuFavDesigns favoriteDesignId={favoriteDesign.favoriteDesignId} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )) || (
            <div className="recentProjects__listNotification">
              <Typography className="recentProjects__notification">{t("youHaveNotCreatedOneYet")}</Typography>
            </div>
          )}
        </div>
      </div>
      <FavoriteDesignsPreview
        displayPreviewTabs
        ref={favoriteDesignsPreviewRef}
      />
    </div>
  );
};

export default RecentFavoriteDesigns;
