import { Grid } from "@mui/material";
import { useRef } from "react";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPixelSize, EIconPercentSize } from "../../../../../enum/EIconSize";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import GridLine from "../../../Components/GridLine/GridLine";
import { isHorizontal, LimitChar } from "../limitChar";
import StandardTile from "../StandardTile";
import TileConfiguration from "../TileConfiguration";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { FrameStyleProps } from "../../../../../interfaces/IFrameAndCoverStyle";

const FourButtons: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.LARGE,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={coverRef}
        data-designcode={props.designCode}
      >
        <StandardTile designCode={props.designCode}>
          <Grid
            className={`container ${border} four-buttons-margin-right four-buttons-margin-bottom printable-area-four-button`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="1"
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="1"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <GridLine
            coverRef={coverRef}
            styleProps={{
              width: "1px",
              height: `calc(100% - (2 * ${FrameStyleProps.get(props.designCode)?.cover.padding}))`,
              position: "absolute",
              left: "calc(50% - 0.5px)",
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
            }}
            enable={props.insertDetail.hasGridLine}
            indexDetail="5"
            cover={props.cover}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[0, 180]}
            defaultOrientation="vertical"
          />
          <Grid
            className={`container ${border} four-buttons-margin-left four-buttons-margin-bottom printable-area-four-button`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  tileDimension={tileDimension}
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="2"
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="2"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <Grid
            className={`container ${border} four-buttons-margin-top rocker-border-right four-buttons-margin-right printable-area-four-button`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="3"
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="3"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
          <GridLine
            coverRef={coverRef}
            styleProps={{
              height: "1px",
              width: `calc(100% - ${FrameStyleProps.get(props.designCode)?.cover.padding} * 2)`,
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
              position: "absolute",
              top: "calc(50% - 0.5px)",
            }}
            enable={props.insertDetail.hasGridLine}
            indexDetail="6"
            cover={props.cover}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[90, 270]}
            defaultOrientation="horizontal"
          />
          <Grid
            className={`container ${border} four-buttons-margin-left four-buttons-margin-top printable-area-four-button`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="4"
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.fourButtons
                      : textFieldProps.vertical.fourButtons
                  }
                  tileName="fourButtons"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="4"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default FourButtons;
