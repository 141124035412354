import { Accordion, AccordionSummary, Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import { useStoreActions, useStoreState } from "../../hooks";
import AddProjectIcon from "../Configurator/Icons/AddProject.svg";
import ArrowDownIcon from "../Configurator/Icons/arrow-down.svg";
import "./FavoriteDesigns.css";
import FavoriteDesignsTable from "./FavoriteDesignsTable";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { FTSTheme } from "../../App";
import BackButton from "../../components/BackButton/BackButton";
import { ABB } from "../../constants/Theme";
import ProfileImg from "../../images/Profile.png";
import FitText from "../../components/FitText/FitText";
import { IProductFavoriteDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";

const FavoriteDesigns: FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ftsTheme = useTheme() as FTSTheme;

  const { currentUser } = useStoreState((state) => state.auth);
  const { projectsSummary, designs, favoriteDesigns } = useStoreState((state) => state.designs.data);
  const { thunkGetProjectsSummary } = useStoreActions((actions) => actions.designs);
  const [unavailableDesigns, setUnavailableDesigns] = useState<IProductFavoriteDesignResponse[]>([]);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const [checked, setChecked] = useState<string[]>([]);
  const [favoriteDesignsList, setFavoriteDesignsList] = useState<IProductFavoriteDesignResponse[]>([]);
  const { thunkGetFavoriteDesign } = useStoreActions((actions) => actions.designs);

  const fetchFavoriteDesigns = async () => {
    try {
      const favDesigns = await thunkGetFavoriteDesign();
      setFavoriteDesignsList(favDesigns);
    } catch (error) {
      console.error("Failed to fetch favorite designs:", error);
    }
  };

  useEffect(() => {
    fetchFavoriteDesigns();
  }, []);

  useEffect(() => {
    if (favoriteDesigns.length !== favoriteDesignsList.length) {
      fetchFavoriteDesigns();
    }
  }, [favoriteDesigns]);

  useEffect(() => {
    if (favoriteDesignsList.length) {
      const unavailableFavoriteDesigns = favoriteDesignsList.filter((favoriteDesign) => {
        return favoriteDesign.countryId !== currentUser?.countryId;
      });
      setUnavailableDesigns(unavailableFavoriteDesigns);
    }
  }, [projectsSummary]);

  useEffect(() => {
    thunkGetProjectsSummary();
  }, []);

  const handleNewProject = () => {
    if (!designs.length) {
      thunkOpenSnackBar({
        message: t("configurator_welcome.designs_not_available"),
        severity: "error",
      });
      return;
    }
    navigate("/start-configurator", {
      state: { isNewProject: true },
    });
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
      navbarMainTitle="navbar_main_title"
      navbarBackGroundColor="#fafafa"
      bodyBackGroundColor={`${ftsTheme.name == ABB ? "#FFFFFF" : "$FAFAFA"}`}
      customPageHeaderOffSection={
        <div
          className="ProfileImg"
          style={{
            width: "100%",
            backgroundImage: `url(${ProfileImg})`,
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        ></div>
      }
    >
      <Box sx={{ position: "absolute", top: 0 }}>
        <BackButton />
      </Box>
      <Grid
        container
        xs={12}
        pt={"5px"}
        pb={"32px"}
      >
        <Grid
          item
          xs={10}
        >
          <Typography
            className="project__title"
            gutterBottom
            component="div"
          >
            {t("favoriteDesigns.label.title")}
          </Typography>
          <Typography
            className="project__description"
            gutterBottom
            component="div"
            sx={{ wordBreak: "break-word", width: 590, height: 40 }}
          >
            {t("favoriteDesigns.label.description")}
          </Typography>
        </Grid>
        <Grid
          item
          alignItems={"flex-end"}
          display={"flex"}
        >
          <Button
            onClick={handleNewProject}
            sx={{
              width: "179px",
              height: "48px",
            }}
            variant="contained"
            endIcon={
              <ReactSVG
                src={AddProjectIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", `width: 16px; height: 16px; fill: #009fe3; `);
                }}
              />
            }
          >
            <FitText
              textTransform="capitalize"
              fontSize={18}
            >
              {t("projects.button.new_project")}
            </FitText>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FavoriteDesignsTable
            favoriteDesigns={favoriteDesignsList}
            checked={checked}
            setChecked={setChecked}
            available
          />
        </Grid>
        <Grid
          item
          xs={12}
          marginTop={5}
        >
          <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <AccordionSummary
              className="projects__accordion_label"
              disabled={!unavailableDesigns.length}
              expandIcon={<ReactSVG src={ArrowDownIcon} />}
            >
              <Typography
                variant="h4"
                fontSize="20px"
                fontWeight={700}
                color="#000000"
              >
                {`${t("projects.accordion.label.projects_unavailable")} (${unavailableDesigns.length})`}
              </Typography>
            </AccordionSummary>

            {unavailableDesigns.length && (
              <FavoriteDesignsTable
                favoriteDesigns={favoriteDesignsList}
                checked={checked}
                setChecked={setChecked}
                available={false}
              />
            )}
          </Accordion>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
};

export default FavoriteDesigns;
